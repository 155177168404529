<template>
  <div class="info_index" v-if="pageShow">
    <div class="info">
      <div class="top">
        <div class="tips">
          管理员【{{employee}}】创建了朋友圈，提醒你去发送哦
        </div>
        <div class="status">{{status == 1 ? '已发送' : '待发送'}}</div>
      </div>
      <div class="cont">
        <h5>朋友圈内容：</h5>
        <div class="momentData">
          <div class="text">{{momentData.show.text.content}}</div>
          <div class="imageType" v-if="momentData.show.type == 'image'">
            <img :src="baseUrl + item.image" alt="" v-for="(item, index) in momentData.show.image" :key="index">
          </div>
           <div class="videoType" v-if="momentData.show.type == 'video'">
            <video :src="baseUrl + momentData.show.video.video" controls="controls"></video>
          </div>
          <div class="linkType" v-if="momentData.show.type == 'link'">
            <img :src="baseUrl + momentData.show.link.link_pic" alt="">
            <div class="left">
              <div class="title">{{momentData.show.link.link_title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed">
      <van-button style="width: 45%;" block type="default" @click="send">发送</van-button>
      <van-button style="width: 45%;" block type="info" color="#294ba3" @click="update">修改</van-button>
    </div>
  </div>
</template>

<script>
import { workMomentInfo, finishMoment } from "../../api/workMoment"
import { shareToExternalMoments } from "../../utils/wxCodeAuth"
export default {
  data() {
    return {
      moment_id: '',
      momentData: {},
      baseUrl: '',
      pageShow: false,
      status: '',
      employee: ''
    }
  },
  created () {
    this.baseUrl = process.env.VUE_APP_API_BASE_URL + '/static/'
    console.log(this.$route.query, 'this.$route.query=======')
    this.moment_id = this.$route.query.momentId
    this.getInfo()
  },
  watch: {},
  methods: {
    getInfo () {
      workMomentInfo({ moment_id: this.moment_id }).then((res) => {
        if (res.code == 200 && res.data) {
          this.momentData = res.data
          this.status = res.data.status
          this.employee = res.data.employee
          this.pageShow = true
        }
      })
    },
    update () {
      this.$router.push({
        path: '/workMoment/create',
        query: {
          momentId: this.moment_id
        }
      })
    },
    send () {
      shareToExternalMoments(true, this.momentData.send, '').then(res=>{
        //跳转企微发布朋友圈页面了
        // console.log(res, '?????????')
        // this.finish()
      })
    },
    finish () {
      finishMoment({moment_id: this.moment_id}).then(res => {
        if (res.code == 200) {
          this.$message.success('发布成功')
          this.$router.push('./index')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.info_index {
  .info {
    padding: 20px 20px 10px;
  }
  .top {
    font-size: 14px;
    background: #f5f9fd;
    display: flex;
    align-items: center;
    padding: 10px;
    .status {
      width: 60px;
      font-size: 12px;
      border: 1px solid #f6dfb6;
      background-color: #fdf5e3;
      text-align: center;
      color: #eac67d;
    }
  }
  .cont {
    margin-top: 15px;
    .momentData {
      padding: 10px 10px;
      width: 100%;
      height: auto;
      font-size: 16px;
      background-color: #f7f7f7;
      .text {
        margin-bottom: 10px;
      }
      .imageType {
        width: 100%;
        img {
          width: 33%;
        }
      }
      .videoType {
        video {
          width: 80%;
          height: 80%;
        }
      }
      .linkType {
        display: flex;
        align-items: center;
        background-color: #f0f0f0;
        padding: 10px;
        font-size: 13px;
        img {
          width: 50px;
          height: 40px;
        }
        .title {
          margin-left: 5px;
          font-weight: 600;
          // width: calc(100% - 60px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

        }
      }
    }
  }
  .fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #f6f7fa;
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

</style>
